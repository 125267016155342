.blogList {
  width: 800px;
  margin: 24px auto;
}
.blogList :global .ant-empty {
  margin-top: 80px;
}
.blogItem {
  padding: 24px 0px 6px 0px;
  border-bottom: 1px solid #d9d9d9;
}
.blogItem .title {
  font-size: 24px;
  margin: 12px 0px;
  cursor: pointer;
}
.blogItem .title:hover {
  color: #1890ff;
}
.blogItem .info {
  display: flex;
  justify-content: space-between;
}
