.taskWrapper {
  display: flex;
  justify-content: space-evenly;
  padding: 8px;
}
.taskWrapper .taskLeft {
  width: 40%;
}
.taskWrapper .taskRight {
  width: 40%;
}
.taskWrapper .taskMsgBox {
  display: flex;
}
.taskWrapper .taskMsgBox .msgTitle {
  font-size: 28px;
  font-weight: bold;
  margin: 0px 8px;
}
.taskWrapper .taskMsgBox .msgContent {
  padding-top: 12px;
  height: 300px;
  width: 302px;
  overflow: auto;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.taskItem {
  width: 300px;
  padding: 0px 6px;
  border-bottom: 1px solid #d9d9d9;
}
.taskItem :global .ant-descriptions .ant-descriptions-header {
  margin-bottom: 6px;
}
.taskItem :global .ant-descriptions .ant-descriptions-row > td {
  padding-bottom: 6px;
}
