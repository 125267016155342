.blogDetail {
  width: 65vw;
  margin: 12px auto;
  background-color: #ffffff;
  padding: 12px;
  flex: 1 1;
}
.blogDetail :global .hljs code {
  display: block;
  padding: 1em;
}
.blogDetail :global p code {
  background-color: #f3f4f4;
  padding: 2px 3px 2px 3px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  text-align: left;
  vertical-align: baseline;
  vertical-align: initial;
  font-size: 0.9em;
  color: #111111;
  font-family: "Lucida Console", Consolas, "Courier", monospace;
}
.blogDetail :global h3 {
  text-align: center;
}
.blogDetail :global p {
  line-height: 28px;
  text-indent: 2em;
  font-size: 16px;
}
.blogDetail .topInfo {
  display: flex;
  justify-content: space-between;
}
.blogDetail .topInfo .leftBack {
  cursor: pointer;
}
.blogDetail .topInfo .leftBack:hover {
  color: #1890ff;
}
.blogDetail .topInfo .date {
  text-align: center;
}
.blogDetail .loading {
  text-align: center;
  margin-top: 100px;
}
