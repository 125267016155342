.blogListM {
  width: 70vw;
  margin: 24px auto;
}
.blogListM :global .ant-empty {
  margin-top: 20vh;
}
.blogItemM {
  padding: 24px 0px 6px 0px;
  border-bottom: 1px solid #d9d9d9;
}
.blogItemM .title {
  font-size: 24px;
  margin: 12px 0px;
  cursor: pointer;
}
.blogItemM .title:hover {
  color: #1890ff;
}
.blogItemM .info {
  display: flex;
  justify-content: space-between;
}
