.myHeader {
  background-color: #d9d9d9;
  display: flex;
}
.myHeader .title {
  text-align: center;
  flex: none;
  color: #808080;
  font-size: 26px;
}
.myHeader .link {
  text-align: center;
  flex: auto;
}
.myHeader .link a {
  margin: 0px 24px;
  color: #1890ff;
}
.myHeader .poem {
  flex: none;
  letter-spacing: 3px;
}
.webSlogan {
  color: #333333;
  text-decoration: none;
}
.webSlogan:hover {
  color: #333333;
}
