.mLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f5f5;
}
.mLayout .headerWrapper {
  flex: none;
}
.mLayout .contentWrapper {
  flex: auto;
  display: flex;
  flex-direction: column;
}
.mLayout .footerWrapper {
  padding: 12px 0px;
  flex: none;
}
