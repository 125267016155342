.layoutWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.headerWrapper {
  background-color: #d9d9d9;
  height: 64px;
  flex: none;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
}
.footerWrapper {
  height: 67px;
  flex: none;
  text-align: center;
}
