.elevator {
  margin: 12px;
}
.elevator .eleName {
  display: flex;
}
.elevator .eleName .name {
  padding: 6px 12px;
  font-size: 32px;
  font-weight: bold;
}
.elevator .eleName .eleScreen .elePosition {
  color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid red;
  font-size: 36px;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
}
.floorItem {
  padding: 6px 12px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
}
.floorItem .floorNum {
  font-size: 24px;
  width: 60px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}
.floorItem .upBtn {
  height: 36px;
  line-height: 36px;
  padding: 0px 8px;
  font-size: 22px;
  cursor: pointer;
}
.floorItem .downBtn {
  height: 36px;
  line-height: 36px;
  padding: 0px 8px;
  font-size: 22px;
  cursor: pointer;
}
