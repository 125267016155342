.ball {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
}
.screenSaver {
  width: 100vw;
  height: calc(100vh - 131px);
  position: relative;
}
