.headerM {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  background-color: #d9d9d9;
}
.headerM .title {
  font-size: 24px;
}
.headerM .icon {
  font-size: 26px;
}
.webSlogan {
  color: #333333;
  text-decoration: none;
}
.webSlogan:hover {
  color: #333333;
}
.menuList {
  width: 120px;
  margin: 60px auto;
}
.menuList .menuItem {
  margin: 24px 0px;
}
.menuList .menuItem a {
  font-size: 16px;
}
