.blogDetail {
  width: 85vw;
  margin: 12px auto;
  background-color: #ffffff;
  padding: 12px;
  flex: 1 1;
}
.blogDetail :global h3 {
  text-align: center;
}
.blogDetail :global p {
  line-height: 28px;
  text-indent: 2em;
  font-size: 16px;
}
.blogDetail .topInfo {
  display: flex;
  justify-content: space-between;
}
.blogDetail .leftBack {
  cursor: pointer;
}
.blogDetail .leftBack:hover {
  color: #1890ff;
}
.blogDetail .date {
  text-align: center;
}
.blogDetail .loading {
  text-align: center;
  margin-top: 100px;
}
