.notFound div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: 60px auto;
}
.notFound .title {
  font-size: 28px;
}
.notFound .desc {
  color: #808080;
}
